import React, { useState, useEffect } from "react";
import Tree from "react-d3-tree";
import Select from "react-select";

// Transform data to hierarchical format
const transformToHierarchy = (assets) => {

  const root = { name: "Assets", children: [] };

  const categories = {};
  assets.forEach((asset) => {
    if (!categories[asset.category]) {
      categories[asset.category] = { name: asset.category, children: [] };
      root.children.push(categories[asset.category]);
    }
    categories[asset.category].children.push({
      name: asset.name,
      type: asset.type,
      supplier: asset.supplier,
      value: asset.value,
      businessUnit: asset.business_unit,
    });
  });

  return root;
};

const App = (props) => {

    const assets = props.assets
    const mode = props.mode
    const [treeData, setTreeData] = useState(assets);

  useEffect(()=>{
    if(assets.length>0){
        transformToHierarchy([])
    }
  },[])
  

  const handleSelectChange = (selectedOption) => {
    // Example: Filtering by business unit
    const filteredAssets = assets.filter((asset) => asset.businessUnit === selectedOption.value);
    setTreeData(transformToHierarchy(filteredAssets));
  };

  const businessUnitOptions = [
    { value: "Product Management", label: "Product Management" },
    { value: "Corporate", label: "Corporate" },
    { value: "Sales", label: "Sales" },
  ];

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* Dropdown Filter */}
      <div style={{ margin: "20px", width: "200px" }}>
        <Select
          options={businessUnitOptions}
          onChange={handleSelectChange}
          placeholder="Filter by Business Unit"
        />
      </div>

      {/* Tree Visualization */}
      <div style={{ flex: 1 }}>
        <Tree
          data={treeData}
          renderCustomNodeElement={({ nodeDatum }) => (
            <g>
              <circle r="15" fill="lightblue" />
              <text fill="black" fontSize="12" x="20" y="5">{nodeDatum.name}</text>
            </g>
          )}
        />
      </div>
    </div>
  );
};

export default App;
