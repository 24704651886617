export const documentationHtml =`
  <div style="display: flex; flex-direction: column; width: 100%; padding: 20px; font-size: 12px; font-family: Arial; line-height: 1.5;">
  <h2 style="font-size: 24px; color: black; font-weight: bold; margin-bottom: 10px;">Oomnitza Table Documentation</h2>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Summary</h3>
  <p style="font-size: 12px; color: black;">
    The table "Assets" in Oomnitza is a comprehensive repository that manages and tracks all assets within the organization. It includes detailed information about each asset, such as its barcode, equipment ID, and other relevant attributes. This table is crucial for maintaining an accurate inventory, ensuring efficient asset management, and supporting decision-making processes related to asset utilization and lifecycle management. The table is designed to handle a large volume of records, making it suitable for enterprise-level asset management needs.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Change Summary</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Change Date</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Created Date</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Changed By</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">November 11, 2024 17:53:37</td>
        <td style="border: 1px solid lightgray;">February 9, 2023 00:44:50</td>
        <td style="border: 1px solid lightgray;">python</td>
      </tr>
    </tbody>
  </table>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Field Breakdown</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Row #</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Field Name</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">1</td>
        <td style="border: 1px solid lightgray;">barcode</td>
        <td style="border: 1px solid lightgray;">Unique identifier for each asset.</td>
      </tr>
      <tr style="font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">2</td>
        <td style="border: 1px solid lightgray;">equipment_id</td>
        <td style="border: 1px solid lightgray;">ID assigned to the equipment for tracking purposes.</td>
      </tr>
      <!-- Add more fields as necessary -->
    </tbody>
  </table>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Technical Details</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Row #</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Field Name</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Technical Details</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">1</td>
        <td style="border: 1px solid lightgray;">barcode</td>
        <td style="border: 1px solid lightgray;">String, max length 255 characters, unique constraint.</td>
      </tr>
      <tr style="font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">2</td>
        <td style="border: 1px solid lightgray;">equipment_id</td>
        <td style="border: 1px solid lightgray;">Integer, auto-increment, primary key.</td>
      </tr>
      <!-- Add more fields as necessary -->
    </tbody>
  </table>
</div>
`
export default documentationHtml