// Initial Workflow Steps Data

export const workflow_data = [
  {
    id: "1",
    name: "workflow_1",
    type: "employee_onboarding",
    nodes : [
    { id: 1, type: "begin", name: 'Create Zendesk Ticket', x: 50, y: 100, prior_node: 0, next_node: 2, actions: [] },
  
    { id: 2, type: "update", name: 'Check Stock', x: 200, y: 100, prior_node: 1, next_node: 3, actions: [
      {id: '1', name: "Get required hardware", source: "People", rule: "Get asset_list.id where user.role equals asset_list.role"},
      {id: '2', name: "Check inventory", source: "Inventory",  rule: "Get in_stock where asset_list equals inventory.asset_id"},
      {id: '3', name: "Result", source: "Inventory",  rule: "Return in-stock for each asset found in asset list"}
    ]},
  
    { id: 3, type: "update", name: 'Provision Applications', x: 350, y: 100, prior_node: 2, next_node: 4, actions: [
      {id: '1', name: "Get required software", source: "People", rule: "Get asset_list.id where user.role equals asset_list.role"},
      {id: '2', name: "Check license availability", source: "Licenses",  rule: "Get in_stock where asset_list equals inventory.asset_id"},
      {id: '3', name: "List Available Applications", source: "Licenses",  rule: "Return available assets for each license available in license list"},
      {id: '4', name: "List Unavailable Applications", source: "Licenses",  rule: "Return unavailable assets for each license NOT available in license list"},
      {id: '5', name: "Submit request purchase request for additional licenses", rule: "Requests",  action: "Submit purchase request in Coupa"},
    ] },
  
    { id: 4, type: "update", name: 'Add to email lists', x: 500, y: 100, prior_node: 3, next_node: 5, actions: [
      {id: '1', name: "Look up default lists", source: "Email Lists", rule: "Get email_list.id where user.role equals email_list.role"},
      {id: '2', name: "Trigger gmail api", source: "gmail api",  rule: "execute gmail api"},
      {id: '2', name: "Send email alert", source: "gmail api",  rule: "Send email if api add is successful"},
    ] },
    { id: 5, type: "update", name: 'Update Zendesk ticket', x: 650, y: 100, prior_node: 4, next_node: 6, actions: [
      {id: '1', name: "Get required assets", source: "People", rule: "Get asset_list.id where user.role equals asset_list.role"},
      {id: '2', name: "Check inventory", source: "Inventory",  rule: "Get in_stock where asset_list equals inventory.asset_id"},
      {id: '3', name: "Result", source: "Inventory",  rule: "Return in-stock for each asset found in asset list"}
    ] },
    { id: 6, type: "end", name: 'Ship hardware', x: 800, y: 100, prior_node: 5, next_node: 0, actions: [
      {id: '1', name: "Get required assets", source: "People", rule: "Get asset_list.id where user.role equals asset_list.role"},
      {id: '2', name: "Check inventory", source: "Inventory",  rule: "Get in_stock where asset_list equals inventory.asset_id"},
      {id: '3', name: "Result", source: "Inventory",  rule: "Return in-stock for each asset found in asset list"}
    ] }
  ],
  connections : [
    { from: 1, to: 2 },
    { from: 2, to: 3 },
    { from: 3, to: 4 },
    { from: 4, to: 5 },
    { from: 5, to: 6 }
  ]
  },


  {
    id: "2",
    name: "workflow_2",
    type: "employee_offboarding",
    nodes : [
    { id: '1', type: "begin", name: 'Create Zendesk Ticket', x: 50, y: 100, prior_node: 0, next_node: 2, actions: [] },
    { id: '2', type: "update", name: 'Generate Hardware List', x: 200, y: 100, prior_node: 1, next_node: 3, actions: []},
    { id: '3', type: "update", name: 'Schedule Decomission', x: 500, y: 100, prior_node: 3, next_node: 5, actions: [] },
    { id: '4', type: "update", name: 'Notify Employee', x: 650, y: 100, prior_node: 4, next_node: 6, actions: [] },
    { id: '5', type: "end", name: 'Execute Decomission', x: 800, y: 100, prior_node: 5, next_node: 0, actions: [] }
  ],
  connections : [
    { from: '1', to: '2' },
    { from: '2', to: '3' },
    { from: '3', to: '4' },
    { from: '4', to: '5' },
  ]
  },

  {
    id: "3",
    name: "workflow_4",
    type: "server_provisioning",
    nodes: [
      { id: "1", type: "begin", name: "Create Ticket", x: 50, y: 150, prior_node: 0, next_node: 2, actions: [] },
      { id: "2", type: "update", name: "Provision Server", x: 200, y: 150, prior_node: 1, next_node: 3, actions: [] },
      { id: "3", type: "update", name: "Apply Configuration", x: 400, y: 150, prior_node: 2, next_node: 4, actions: [] },
      { id: "4", type: "update", name: "Validate Setup", x: 600, y: 150, prior_node: 3, next_node: 5, actions: [] },
      { id: "5", type: "end", name: "Close Ticket", x: 800, y: 150, prior_node: 4, next_node: 0, actions: [] }
    ],
    connections: [
      { from: "1", to: "2" },
      { from: "2", to: "3" },
      { from: "3", to: "4" },
      { from: "4", to: "5" }
    ]
  }

]

export default workflow_data