import React,{useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'

const Header = ({contextApi, appName, logo}) => {

  const dispatch = useDispatch()

  //Get Global States
  const mode = contextApi.mode
  
  //Set Local States
  const [showUserMenu, setShowUserMenu] = useState(false)
  
  const [userMenuTop, setUserMenuTop] = useState(null)
  
  const UserMenuStyle={
    border: "3px solid rgba(200,200,200,0.5)", 
    width: "200px",
    zIndex:999, 
    fontSize: "12px", 
    backgroundColor: "white",
    top: "65px"
  }


  return (
    <div className="flex relative w-100 h-[50px] justify-between items-center bg-black text-white">
        
        <div className="flex  w-full md:w-1/2 ms-3 align-items-center">
            <div className="text-[24px] ms-1"> Data Center Manager</div>
        </div>

        </div>
  )
}

export default Header