import React, { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import { useSelector } from 'react-redux';

Chart.register(...registerables);

const RevenueForecastChart = (props) => {
  const chartRef = useRef(null);
  const mode = useSelector(state => state.environment.mode);

  // Data
  const revenueData = [10000, 12000, 13000, 15000, 16000, 14000, 10000, 12000, 13000, 15000, 16000, 14000];
  const forecastData = [17000, 18000, 19000];
  const forecastLowerBound = [16000, 17000, 18000];
  const forecastUpperBound = [18000, 19000, 20000];

  // Calculate moving average
  const movingAverage = (data, windowSize = 3) => {
    return data.map((_, idx, arr) => {
      if (idx < windowSize - 1) return null; // Don't plot moving average for incomplete window
      const windowData = arr.slice(idx - windowSize + 1, idx + 1);
      return windowData.reduce((sum, value) => sum + value, 0) / windowSize;
    });
  };

  const revenueMovingAverage = movingAverage(revenueData);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    if (Chart.getChart(chartRef.current)) {
      Chart.getChart(chartRef.current).destroy();
    }

    const confidenceIntervalPlugin = {
      id: 'confidenceIntervalPlugin',
      afterDatasetDraw(chart) {
        const { ctx, chartArea: { top, bottom }, scales: { x, y } } = chart;
        chart.data.labels.forEach((label, index) => {
          if (index >= revenueData.length && index < revenueData.length + forecastData.length) {
            const forecastIndex = index - revenueData.length;
            const xPos = x.getPixelForValue(label) + 15;
            const yPosUpper = y.getPixelForValue(forecastUpperBound[forecastIndex]);
            const yPosLower = y.getPixelForValue(forecastLowerBound[forecastIndex]);

            ctx.save();
            ctx.strokeStyle = 'rgba(150, 150, 150, 1)';
            ctx.lineWidth = 2;

            ctx.beginPath();
            ctx.moveTo(xPos, yPosUpper);
            ctx.lineTo(xPos, yPosLower);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(xPos - 5, yPosUpper);
            ctx.lineTo(xPos + 5, yPosUpper);
            ctx.stroke();

            ctx.beginPath();
            ctx.moveTo(xPos - 5, yPosLower);
            ctx.lineTo(xPos + 5, yPosLower);
            ctx.stroke();

            ctx.restore();
          }
        });
      }
    };

    Chart.register(confidenceIntervalPlugin);

    const revenueForecastChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
          '30 Days', '90 Days', '180 Days'
        ],
        datasets: [
          {
            label: 'Revenue',
            data: revenueData,
            backgroundColor: 'rgba(0, 150, 225, 0.5)',
            borderColor: 'rgba(0, 150, 225, 1)',
            borderWidth: 1,
            barPercentage: 0.8,
            categoryPercentage: 1
          },
          {
            label: 'Forecasted Revenue',
            data: [...Array(revenueData.length).fill(null), ...forecastData],
            backgroundColor: 'rgba(200, 200, 200, 0.5)',
            borderColor: 'rgba(150, 150, 150, 1)',
            borderWidth: 2,
            borderDash: [5, 5],
            barPercentage: 1,
            categoryPercentage: 1
          },
          {
            label: 'Revenue Moving Average',
            data: revenueMovingAverage,
            type: 'line',
            borderColor: 'rgba(200, 200, 200, 1)',
            backgroundColor: 'rgba(200, 200, 200, 0.2)',
            borderDash: [5, 5], // Makes the line dashed
            borderWidth: 2,
            pointRadius: 3, // Hides data points on the line
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            beginAtZero: true,
            ticks: {
                color: 'rgb(150,150,150)',
                font: {
                  size: 12,
                },
                align: 'center'
            },
            title: {
                display: true,
                text: props.xAxisTitle,
                color: getComputedStyle(document.documentElement).getPropertyValue(`--primary-color-mode-${mode}`),
                font: {
                  weight: 'bold',
                },
              },
          },
          y: {
            stacked: true,
            beginAtZero: true,
            ticks: {
              color: "gray",
              font: {
                size: 12,
              },
            },
            title: {
              display: true,
              text: props.yAxisTitle,
              color: getComputedStyle(document.documentElement).getPropertyValue(`--primary-color-mode-${mode}`),
              font: {
                size: 12,
              },
              rotate: -90,
            },
            grid: {
              color: getComputedStyle(document.documentElement).getPropertyValue(`--grid-line-mode-${mode}`),
            },
          }
        },
        options: {
            plugins: {
              tooltip: {
                enabled: true, // Ensure tooltips are enabled
                callbacks: {
                  label: (tooltipItem) => {
                    if (tooltipItem.dataset.label === 'Revenue Moving Average') {
                      return `Moving Average: ${tooltipItem.raw}`;
                    }
                    return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
                  }
                }
              },
            },
            interaction: {
              mode: 'nearest', // Ensures tooltip appears nearest to cursor
              axis: 'x',       // Follows the x-axis position of the cursor
              intersect: false // Allows tooltip to display on hover without needing exact point
            },
        },
    },
    });

    return () => {
      revenueForecastChart.destroy();
      Chart.unregister(confidenceIntervalPlugin);
    };
  }, [revenueData, forecastData, forecastLowerBound, forecastUpperBound]);

  return (
    <div style={{ width: '100%', height: '350px' }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default RevenueForecastChart;
