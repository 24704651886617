import React, {useEffect, useState} from 'react'
import Kanban from './Kanban'
import * as oomnielabsApi from '../../../apis/oomnielabs'

const Lifecycle = () => {

  const [assets, setAssets] = useState([])
  const [stages, setStages] = useState([])

  const getAssets = async()=>{
    const response = await oomnielabsApi.getTable("assets")
    console.log(response)
    const assetData = response.data
    setAssets(assetData)

    // const stageSet = new Set()
    // assetData.forEach((item,index)=>{
    //   stageSet.add(item.stage)
    // })
    // const stageList = Array.from(stageSet)
    setStages(["Ordered", "Receiving", "In Inventory", "Provisioning", "Deployed", "Maintenance", "Decommissioned"])
  }

  useEffect(()=>{
    getAssets()
  },[])

  return (
    <div className="flex w-full h-[100vh]">
      {assets.length>0  && 
        <Kanban assets={assets} stages={stages}/>
      }
    </div>
  )
}

export default Lifecycle
