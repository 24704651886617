export const documentationHtml = `

  <div style="display: flex; flex-direction: column; width: 100%; padding: 20px; font-size: 12px; font-family: Arial; line-height: 1.5;">
  <h2 style="font-size: 24px; color: black; font-weight: bold; margin-bottom: 10px;">Jamf Asset Integration Documentation</h2>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Summary</h3>
  <p style="font-size: 12px; color: black;">
    The Jamf Asset Integration is designed to streamline the management of Apple devices within an enterprise environment. By connecting Jamf Pro, a comprehensive enterprise management software for Apple platforms, with Oomnitza, this integration allows IT departments to efficiently manage Mac, iPad, iPhone, and Apple TV devices alongside other IT assets. The integration facilitates the synchronization of device data, enabling the identification of anomalous behaviors such as unexpected IP addresses, devices that have not checked in for extended periods, and other critical identifiers that may require action. This integration supports the automation of asset tracking, ensuring that device information is up-to-date and accurate, thereby enhancing operational efficiency and security compliance.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Change Summary</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Change Date</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Changed By</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">December 20, 2023 15:11:56</td>
        <td style="border: 1px solid lightgray;">dcce03dd4207432a81ca0fc6a4fa6690</td>
        <td style="border: 1px solid lightgray;">Updated integration configuration and mapping details.</td>
      </tr>
    </tbody>
  </table>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Technical Specifications</h3>
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Key Count: 64</h4>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Key</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Mapping Data</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Transformation Rules</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Expected Values</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Data Type</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Dependencies</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">computer.general.id</td>
        <td style="border: 1px solid lightgray;">ID</td>
        <td style="border: 1px solid lightgray;">None</td>
        <td style="border: 1px solid lightgray;">Integer</td>
        <td style="border: 1px solid lightgray;">Integer</td>
        <td style="border: 1px solid lightgray;">None</td>
      </tr>
      <tr style="font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">computer.general.name</td>
        <td style="border: 1px solid lightgray;">Computer Name</td>
        <td style="border: 1px solid lightgray;">None</td>
        <td style="border: 1px solid lightgray;">String</td>
        <td style="border: 1px solid lightgray;">String</td>
        <td style="border: 1px solid lightgray;">None</td>
      </tr>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">computer.general.udid</td>
        <td style="border: 1px solid lightgray;">UDID</td>
        <td style="border: 1px solid lightgray;">None</td>
        <td style="border: 1px solid lightgray;">String</td>
        <td style="border: 1px solid lightgray;">String</td>
        <td style="border: 1px solid lightgray;">None</td>
      </tr>
      <!-- Additional rows for each key -->
    </tbody>
  </table>
</div>

`
export default documentationHtml