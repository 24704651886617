import React, { useState, useRef, useEffect } from 'react';
import * as oomnielabsApi from '../../apis/oomnielabs'
import { useSelector } from 'react-redux';
import Workflow from './Workflow'
import workflows_data from './data/workflows.js'

const Generate = () => {

  const mode = useSelector(state => state.environment.mode);
  const [formData, setFormData] = useState({})
  const [showWorkflow, setShowWorkflow] = useState(false)
  const [workflowData, setWorkflowData] = useState(null)

  const handleChange = (e)=>{
    const {name, value} = e.target
    setFormData({...formData,...{[name]:value}})
  }


  const handleSubmit = async () =>{

    setShowWorkflow(false)

    const app_name = "workflow_generator/src/find_most_similar_workflow"
    const main_function = "run_similar_workflow"
    const parameters = formData.prompt

    const substrings = ["employee onboarding workflow", "default", "standard"];
    const containsSubstring = substrings.some(substring => parameters.toLowerCase().includes(substring));
  
    let response
    if (containsSubstring || parameters ==="employee onboarding workflow"){
      
      response = workflows_data.find(i=>i.name==="workflow_1")
      console.log(response)

      setWorkflowData(response)
      setShowWorkflow(true)

    }else{

      try{
        response = await oomnielabsApi.pythonApp(app_name,main_function,parameters)
        console.log(response)
        
        if(response.status ==200){
          setWorkflowData(response.data)
          setShowWorkflow(true)
        }
      }
      catch(error){
        return
      }
    }
    
   
  }
  
  const handleReset = async () =>{
    setFormData({
      prompt:""
    })
    setShowWorkflow(false)
  }

  
  return (
    <div className={`flex flex-col w-full h-[100%] items-center`}>

    <div className={`flex w-full h-auto panel-mode-${mode} rounded-md justify-between items-center p-3`}>
        
        <input
          id="prompt" 
          name="prompt"
          label="Desribe the document you'd like to generate"
          value = {formData.prompt}
          onChange = {(e)=>handleChange(e)}
          placeholder = "Describe the workflow you'd like to generate"
          className={`flex w-full h-[50px] outline-none bg-mode-${mode} p-3 color-[rgb(0,150,225)] rounded-lg text-[rgb(0,150,225)]`}
          autoComplete="off" 
        />

        <div className="flex ms-3 p-1 items-end justify-end">

          <div className="flex">
            <button
                className={`button-mode-${mode}`}
                onClick = {(e)=>handleSubmit(e)}
            >Generate</button>
          </div>

          <div className="flex">
            <button
                className={`button-mode-secondary-${mode} ms-2`}
                onClick = {()=>handleReset()}
            >
              Reset
            </button>
          </div>

        </div>

    </div>

    {showWorkflow && 
      <div className={`flex w-full h-[600px] overflow-y-auto mt-5`}>
        <Workflow workflowData = {workflowData}/>
      </div>
    }

    </div>
  );
};

export default Generate;

