import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../redux/slices/datacenterSlice';

const Navbar = ({contextApi}) => {

const dispatch = useDispatch();
const mode = contextApi.mode
const currentPage = useSelector(state => state.datacenter.currentPage);

  return (
    <div>
         <div className={`flex h-[50px] w-full items-center ps-[50px text-[16px]]
          header-strip-mode-${mode} primary-color-${mode}
          transition duration-500 mb-3` }>

          <div 
            className={`primary-color-mode-${mode} w-[75px] m-2 cursor-pointer p-2 text-center  hover:bg-gray-300 hover:rounded-sm transition duration-500`}
            onClick = {(e)=>dispatch(setCurrentPage("mapview"))}
            
          >Map</div>
           
          <div 
            className={`primary-color-mode-${mode} w-[75px] m-2 cursor-pointer p-2 text-center  hover:bg-gray-300  hover:rounded-sm transition duration-500`}
            onClick =  {(e)=>dispatch(setCurrentPage("details"))}
          >Details</div>

          
          <div 
          className={`primary-color-mode-${mode} w-[75px] m-2 cursor-pointer p-2 text-center  hover:bg-gray-300 hover:rounded-sm transition duration-500`}
          onClick =  {(e)=>dispatch(setCurrentPage("insights"))}
          >Insights</div>

        </div>
    </div>
  )
}

export default Navbar
