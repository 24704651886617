import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist';

export const oomnieInsightsGenieSlice = createSlice({
  name: 'oomnieInsightsGenie',
  initialState: {
    pages: [],
    currentPage: "summary",
  },
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // Reset to initialState when PURGE is triggered
      state.pages = [];
      state.currentPage = "summary";
    });
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentPage, setcurrentApp, setPageList} = oomnieInsightsGenieSlice.actions

export const clearStorage = () => ({ type: PURGE, key: 'oomnieInsightsGenieStorage', result: () => null });

export default oomnieInsightsGenieSlice.reducer