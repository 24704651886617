export const documentationHtml = `
<div style="display: flex; flex-direction: column; width: 100%; padding: 20px; font-size: 12px; font-family: Arial; line-height: 1.5;">
  <h2 style="font-size: 24px; color: black; font-weight: bold; margin-bottom: 10px;">Oomnitza Workflow Configuration Change Documentation</h2>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">1. Workflow Summary</h3>
  <p style="font-size: 12px; color: black;">
    The "Reorder Notification" workflow is designed to monitor inventory levels and send notifications when stock levels fall below a predefined minimum threshold. This workflow is triggered by specific transaction types such as "Transfer - Send", "Deprecate", and "Assign". Upon activation, it evaluates the current stock against the minimum limit set for each accessory in the stockroom. If the stock is below the threshold, notifications are sent via email and workstream to designated users, and a ticket is created in Freshservice to ensure timely reordering of the accessory.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">2. Change Log</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Change Date</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Changed By</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">October 28, 2022 10:31:20</td>
        <td style="border: 1px solid lightgray;">Sunita Moka (smoka)</td>
        <td style="border: 1px solid lightgray;">Activated the workflow.</td>
      </tr>
    </tbody>
  </table>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">3. Workflow Steps</h3>
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 1: Begin</h4>
  <p style="font-size: 12px; color: black;">
    The workflow begins when a new transaction of type "Transfer - Send", "Deprecate", or "Assign" is created in the system.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 2: Conditional Threshold</h4>
  <p style="font-size: 12px; color: black;">
    This step checks if the total quantity of accessories in the stockroom is less than the minimum limit set for that accessory. If true, the workflow proceeds to the notification step.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 3: Notification</h4>
  <p style="font-size: 12px; color: black;">
    Notifications are sent via email and workstream to specified users and roles. The message includes details about the accessory, current stock, reorder amount, and stockroom.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 4: Freshservice Create Ticket</h4>
  <p style="font-size: 12px; color: black;">
    A ticket is created in Freshservice with details about the accessory that needs reordering. This ensures that the responsible team is alerted to take action.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">4. Technical Details</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Step</th>
        <th style="background-color: white; color: black; font-weight: bold; font-size: 10px; border: 1px solid lightgray;">Technical Details</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">Begin</td>
        <td style="border: 1px solid lightgray;">Triggered by new transactions of type "Transfer - Send", "Deprecate", or "Assign".</td>
      </tr>
      <tr style="font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">Conditional Threshold</td>
        <td style="border: 1px solid lightgray;">Compares <code>stockrooms_accessories.quantity_total</code> with <code>stockrooms_accessories.accessories_min_limit</code> using the "less than" operator.</td>
      </tr>
      <tr style="background-color: rgb(245,245,245); font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">Notification</td>
        <td style="border: 1px solid lightgray;">Sends notifications to users with IDs: b23d03182c704c228abcc93a004abbf9, e95d2c89b2fb7b54a1db979b6bb6d8b0, cd98cb59ed7994004fc250a8b8c6483b. Subject: "Reorder {{accessory}} for {{stockroom}}".</td>
      </tr>
      <tr style="font-size: 10px; color: black;">
        <td style="border: 1px solid lightgray;">Freshservice Create Ticket</td>
        <td style="border: 1px solid lightgray;">POST request to <code>https://arista.freshservice.com/api/v2/tickets</code> with JSON body containing accessory details. Auth config reference: 4bcf833c-99e5-4a53-a897-4206171a1016.</td>
      </tr>
    </tbody>
  </table>
</div>

`

export default documentationHtml