export const documentationHtml =`

<div style="display: flex; flex-direction: column; width: 100%; padding: 20px; font-size: 12px; font-family: Arial; line-height: 1.5;">
  <h2 style="font-size: 24px; color: black; font-weight: bold; margin-bottom: 10px;">Oomnitza Workflow Configuration Change Documentation</h2>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">1. Workflow Summary</h3>
  <p style="font-size: 12px; color: black;">
    The "Inbound Delivery" workflow is designed to automate the notification process for return package deliveries within the Oomnitza system. This workflow is triggered when a return order ticket number is present, the asset status is "Return Pending (I)", and a tracking number is available with FedEx as the shipping carrier. Upon these conditions being met, the system sends a notification and email to specified recipients, confirming the delivery of the return package. This ensures timely communication and efficient handling of return processes, reducing manual intervention and potential errors.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">2. Change Log</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr style="background-color: white; color: black; font-weight: bold; font-size: 10px;">
        <th style="border: 1px solid lightgray; padding: 5px;">Change Date</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Changed By</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Description of Change</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">October 27, 2024 10:23:18</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Migration Tool</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Initial creation of the "Inbound Delivery" workflow configuration.</td>
      </tr>
    </tbody>
  </table>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">3. Workflow Steps Breakdown</h3>
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 1: Begin</h4>
  <p style="font-size: 12px; color: black;">
    The workflow begins by checking if the asset has a return order ticket number, the status is "Return Pending (I)", and a tracking number is available with FedEx as the carrier.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 2: Notify</h4>
  <p style="font-size: 12px; color: black;">
    If the conditions are met, the system sends a notification and email to the specified recipients, including internal users and external email addresses, confirming the delivery of the return package.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 3: End</h4>
  <p style="font-size: 12px; color: black;">
    The workflow concludes after the notification is sent, ensuring that all relevant parties are informed of the delivery status.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">4. Technical Details</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr style="background-color: white; color: black; font-weight: bold; font-size: 10px;">
        <th style="border: 1px solid lightgray; padding: 5px;">Component</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Details</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">Entry Point</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Checks for non-null return order ticket number, status "Return Pending (I)", and non-null tracking number with FedEx as the carrier.</td>
      </tr>
      <tr style="background-color: white; color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">Notify Block</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Sends notifications and emails to specified users and external addresses. Includes dynamic fields such as {{assigned_to}}, {{return_order_ticket_number}}, and {{model}}.</td>
      </tr>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">End Point</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Marks the completion of the workflow after notifications are sent.</td>
      </tr>
    </tbody>
  </table>
</div>

                    `

export default documentationHtml