import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AirConditioningScheduleChart = () => {
  // Data for each hour of the day (0-23) across 7 days of the week.
  // '1' indicates the AC should be on, '0' otherwise.
  const acData = [
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Monday
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Tuesday
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Wednesday
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Thursday
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Friday
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Saturday
    [0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0], // Sunday
  ];

  const data = {
    labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
    datasets: acData.map((dayData, index) => ({
      label: `Hour ${index}`,
      data: dayData,
      backgroundColor: dayData.map((hour) => (hour === 1 ? 'blue' : 'lightgray')),
      barPercentage: 1.0,
      categoryPercentage: 1.0,
    })),
  };

  const options = {
    responsive: true,
    indexAxis: 'y', // Makes the bar chart horizontal
    scales: {
      x: {
        beginAtZero: true,
        max: 1,
        ticks: {
          display: false, // Hide the x-axis labels to avoid clutter
        },
      },
      y: {
        stacked: true, // Stack the bars horizontally
      },
    },
    plugins: {
      legend: {
        display: false, // Hide the legend to focus on the schedule
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            const hour = context.dataset.label;
            return context.raw === 1 ? `AC On - ${hour}` : '';
          },
        },
      },
    },
  };

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto' }}>
      <h3>Air Conditioning Schedule</h3>
      <Bar data={data} options={options} />
    </div>
  );
};

export default AirConditioningScheduleChart;
