import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../apis/oomnielabs'
import Table from '../../components/Table'

const Workflows = () => {

  const mode = useSelector(state=>state.environment.mode)
  const [data, setData] = useState([])

  const getData = async()=>{
    const response = await oomnielabsApi.getTable("workflows")
    setData(response.data)
  }

  useEffect(()=>{
    getData()
  },[])

  const handleRecordSelect = ()=>{
    console.log()
  }

  const hiddenColumns = ["id", "record_created", "data"]

  return (
    <div className={`flex flex-col w-full h-[100%] body-mode-${mode} mt-5 p-3`}>
      
      <div className={`flex w-full text-[20px]`}>Workflows</div>
      {
      data.length>0 && <div className={`flex flex-col w-full h-[600px]`}>
        <Table 
            data = {data}
            includeRowSelect = {true}
            formatHeader = {true}
            onRowSelected = {(record)=>handleRecordSelect(record)}
            hiddenColumns = {hiddenColumns}
            mode = {mode}
        />
      </div>
      }

    </div>
  )
}

export default Workflows
