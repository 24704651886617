import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ColumnChart from './ColumnChart'
import DoughnutChart from './DoughnutChart'
import StackedBarChart from './StackedBarChart'
import TwoBarChart from './TwoBarChart';
import Forecast from './Forecast'
import Input from '../../../components/Input'
import * as oomnielabsApi from '../../../apis/oomnielabs'
import MultiInput from '../../../components/MultiInput'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Summary = ({contextApi}) => {

  const mode = useSelector(state => state.environment.mode);

  const [filterData, setFilterData] = useState([])

  const [formData, setFormData] = useState({
    start_date:"",
    end_date:"",
    time_interval:"",
    filters: filterData
  })

  const handleInputChange = (e)=>{
    const {name, value} = e.target
    console.log({name, value})
    setFormData({...formData,[name]:value})
  }

  const handleFilterInputChange = (e)=>{
    const {name, value} = e.target
    console.log({name, value})
    const filterData = {...filterData,
      [name]:value
    }
    setFilterData(filterData)
    setFormData({...formData,filters:filterData})
  }

  const handleUpdateFilter = (e)=>{
    console.log(filterData)
  }

  const getAssetData = async()=>{
    const app_name = "asset_analysis"
    const main_function = "run_analysis"
    const parameters = formData
    const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
    console.log(response)

  }

  // const filters = [
  //   {"field_name": "business_unit", "operator": "=", "value":"Research & Development", "data_type":df["business_unit"].dtype, "aggregation": False, "aggregation_method": None},
  //   # {"field_name": "original_purchase_price", "operator": ">", "value":10000,"data_type":df["original_purchase_price"].dtype, "aggregation": True, "aggregation_method":"sum"}
  // ]

  return (
    <div className="flex flex-col h-[100vh] fade-in w-full items-center overflow-y-scroll">

    <div className={`flex w-[80%] justify-center mt-3 mb-3 text-[24px] transition duration-500 title-text-mode-${mode}`}>
        
        {/* Charts */}
        <div className="flex flex-col w-full">

          <div className="flex w-full h-[400px] justify-between overflow-hidden mb-3">

            <div className={`flex flex-col rounded shadow-sm w-full min-w-[500px] h-[100%}
                primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
              `}>
                <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Asset Volume Trend & Demand Forecast</div>
                <div className="flex w-full p-3">
                  <Forecast />
                </div>
            </div>

          </div>

          {/* Cost Chart */}
          <div className="flex w-full h-[300px] overflow-hidden mb-3">
              
              <div className="flex mx-auto w-full h-auto justify-between">

                <div className={`relative flex flex-col rounded shadow-sm w-1/2 min-w-[250px]
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode} shadow-lg
                  `}
                  >
                    <div className="flex w-full h-[100%] justify-center items-center">
                      <DoughnutChart
                        labels = {['Q1', 'Q2', 'Q3', 'Q4']}
                        values = {[50, 20, 30, 40]}
                        mode = {mode}
                      />
                    <div className="absolute top-[50%] justify-center flex flex-col">
                      <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}`}>$140M</div>
                      <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}`}>Total Spend</div>
                    </div>
                    </div>
                </div>

                <div className={`flex flex-col rounded shadow-sm w-full min-w-[500px] h-[100%] ms-3
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Spend vs Budget</div>
                    <div className="flex w-full">
                      <TwoBarChart
                        label = "Quarter"
                        labels = {['Q1', 'Q2', 'Q3', 'Q4']}
                        datasets = {[
                          {
                            label: 'Actual Spend',
                            values: [70, 65, 75, 80],
                            backgroundColor: 'rgba(200, 200, 200, 0.6)',
                          },
                          {
                            label: 'Budget',
                            values: [30, 35, 25, 20],
                            backgroundColor: 'rgba(200, 50, 100, 0.6)',
                          },
                        ]}
                        barColor = "rgba(0,200,180,0.5)"
                        yAxisTitle = "Percent"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                </div>

              </div>
          </div>


          {/* Cycle Count */}
          <div className="flex w-full h-[300px] justify-between overflow-hidden mb-3">
              <div className="flex w-full">

                <div className={`relative flex flex-col rounded shadow-sm w-1/4 min-w-[250px]
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex w-full h-[100%] justify-center items-center">
                      <DoughnutChart
                        labels = {['Blind', 'Dynamic']}
                        values = {[30,70]}
                        series1Color = 'rgba(200, 200, 200, 0.6)'
                        series2Color = 'rgba(50, 200, 100, 0.6)'
                        mode = {mode}
                      />
                    <div className="absolute top-[45%] justify-center flex flex-col">
                      <div className={`flex w-full justify-center font-bold text-[32px] primary-color-mode-${mode}}`}>20%</div>
                      <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}}`}>Overall Cycle Count</div>
                    </div>
                    </div>
                </div>

                <div className={`flex flex-col rounded shadow-sm w-3/4 min-w-[400px] h-100 ms-3
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Cycle Count By Asset Type</div>
                    <div className="flex w-full h-5/6 ps-3 pe-3">
                      <StackedBarChart
                        label = "Cycle Count"
                        labels = {['Servers', 'Network', 'Storage', 'Power & Cooling', 'Parts']}
                        datasets = {[
                          {
                            label: 'Dynamic',
                            values: [70, 65, 75, 80, 72],
                            backgroundColor: 'rgba(50, 200, 100, 0.6)',
                          },
                          {
                            label: 'Blind',
                            values: [30, 35, 25, 20, 28],
                            backgroundColor: 'rgba(200, 200, 200, 0.6)',
                          },
                        ]}
                        barColor = "rgba(0,200,180,0.5)"
                        yAxisTitle = "Percent"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                </div>
              
                <div className={`flex flex-col rounded shadow-sm w-3/4 min-w-[500px] h-100 ms-3
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Cycle Count By Month</div>
                    <div className="flex h-5/6 ps-3 pe-3 w-full">
                      <ColumnChart
                        label = "Total Data Center Cost Trend"
                        labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                        values = {[20, 30, 28, 15, 20, 24, 18, 26, 25, 28, 22, 20]}
                        barColor = "rgba(0,150,255,0.5)"
                        yAxisTitle = "Percent"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                </div>
              
              </div>

          </div>

          
          {/* Power Consumptions */}
          <div className="flex w-full h-[300px] justify-between overflow-hidden mb-3">
              <div className="flex w-1/2">
                <div className={`relative flex flex-col rounded shadow-sm w-1/4 min-w-[200px]
                        primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex w-full h-[100%] justify-center items-center">
                      <DoughnutChart
                        labels = {['California', 'Ohio', 'Virgina', 'Oregon']}
                        values = {[1.0, 0.6, 0.5, 0.4]}
                        mode = {mode}
                        fontSize = {12}
                      />
                    <div className="absolute top-[55%] justify-center flex flex-col">
                      <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}}`}>2.5M</div>
                      <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}}`}>Kilowatt-Hours</div>
                    </div>
                    </div>
                </div>

                <div className={`flex flex-col rounded shadow-sm w-3/4 min-w-[300px] h-100 ms-3
                        primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Monthly Power Consumption</div>
                    <div className="flex w-full h-5/6 ps-3 pe-3">
                      <ColumnChart
                        label = "kwH"
                        labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                        values = {[200, 180, 175, 175, 180, 190, 200, 220, 235, 245, 250, 245]}
                        barColor = "rgba(0,200,180,0.5)"
                        yAxisTitle = "Thousand kWh"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                  </div>
              </div>
              

              <div className="flex w-1/2 ms-3">
                <div className={`relative flex flex-col rounded shadow-sm w-1/4 min-w-[200px]
                      primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex w-full h-[100%] justify-center items-center">
                      <DoughnutChart
                        labels = {['California', 'Ohio', 'Virgina', 'Oregon']}
                        values = {[4, 3, 2, 3]}
                        mode = {mode}
                        fontSize = {12}
                      />
                    <div className={`absolute top-[55%] justify-center flex flex-col`}>
                      <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}}`}>1.05</div>
                      <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}}`}>Metric Tons CO2</div>
                    </div>
                    </div>
                </div>

                <div className={`flex flex-col rounded shadow-sm w-3/4 min-w-[300px] h-100 ms-3
                      primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Carbon Emissions</div>
                    <div className="flex w-full h-5/6 ps-3 pe-3">
                      <ColumnChart
                        label = "kwH"
                        labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                        values = {[84, 75.6, 73.5, 73.5, 75.6, 79.8, 84, 92.4, 98.7, 102.9, 105, 102.9]}
                        barColor = "rgba(0,200,180,0.5)"
                        yAxisTitle = "Metric Tons CO2"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                  </div>
              </div>
            

          </div>


          {/* Uptime */}
          <div className="flex w-full h-[300px] justify-between overflow-hidden mb-3">
              <div className="flex w-full">
                <div className={`relative flex flex-col rounded shadow-sm w-1/4 min-w-[250px]
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex w-full h-[100%] justify-center items-center">
                      <DoughnutChart
                        labels = {['Downtime', 'Uptime']}
                        values = {[15, 85]}
                        mode = {mode}
                      />
                    <div className="absolute justify-center flex flex-col">
                      <div className={`flex w-full justify-center font-bold text-[32px] primary-color-mode-${mode}`}>95%</div>
                      <div className={`flex w-full justify-center text-[14px] secondary-color-mode-${mode}`}>Average Uptime</div>
                    </div>
                    </div>
                </div>
                <div className={`flex flex-col rounded shadow-sm w-3/4 min-w-[300px] h-100 ms-3
                      primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Monthly Average Uptime</div>
                    <div className="flex w-full h-5/6 ps-3 pe-3">
                      <ColumnChart
                        label = "Average Uptime"
                        labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                        values = {[86, 87, 88, 92, 95, 96, 99, 99, 98, 97, 98, 99]}
                        barColor = "rgba(0,200,180,0.5)"
                        yAxisTitle = "Percent"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                </div>
              </div>
          </div>


          {/* Response Time */}
          <div className="flex w-full h-[300px] justify-between overflow-hidden mb-3">
              <div className="flex w-full">
                <div className={`relative flex flex-col rounded shadow-sm w-1/4 min-w-[250px]
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex w-full h-[100%] justify-center items-center">
                      <DoughnutChart
                        labels = {['Downtime', 'Uptime']}
                        values = {[15, 85]}
                        mode = {mode}
                      />
                    <div className="absolute justify-center flex flex-col">
                      <div className={`flex w-full justify-center font-bold text-[32px] primary-color-mode-${mode}`}>25</div>
                      <div className={`flex w-full justify-center text-[14px] secondary-color-mode-${mode}`}>Milliseconds</div>
                    </div>
                    </div>
                </div>
                <div className={`flex flex-col rounded shadow-sm w-3/4 min-w-[300px] h-100 ms-3
                    primary-color-mode-${mode} panel-mode-${mode} border-mode-${mode}
                  `}>
                    <div className="flex text-[20px] h-[40px] justify-center items-center font-bold">Monthly Average Respose Time</div>
                    <div className="flex w-full h-5/6 ps-3 pe-3">
                      <ColumnChart
                        label = "Average Uptime"
                        labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                        values = {[25.7, 32,4, 28.9, 22.1, 20.1, 25.3, 26.4, 18.9, 24.3, 29.2, 25.3, 24.9]}
                        barColor = "rgba(0,200,180,0.5)"
                        yAxisTitle = "Percent"
                        xAxisTitle = ""
                        mode = {mode}
                      />
                    </div>
                </div>
              </div>
          </div>

        </div>

  {/* Filter Menu */}
  {/* <div className={`panel-mode-${mode} w-1/5 min-w-[300px] h-auto p-3`}>

      <div className="flex flex-col w-full p-2 border-b-gray-300 border-b-[1px] mb-3" >

        <div className="flex w-[100%]">
          <DatePicker
              selected={formData.start_date} // Bind selected date to state
              onChange={(date) => handleInputChange({ target: { name: 'start_date', value: new Date(date.setHours(0, 0, 0, 0)) } })} // Call handleChange with a Date
              name="start_date"             
              dateFormat="MM/d/yyyy"    
              placeholderText="Start Date"
              className={`datepicker-mode-${mode}`}
            />
        </div>

          <div className="flex w-[100%]">
          <DatePicker
            selected={formData.end_date} // Bind selected date to state
            onChange={(date) => handleInputChange({ target: { name: 'end_date', value: new Date(date.setHours(0, 0, 0, 0)) } })} // Call handleChange with a Date
            name="end_date"             
            dateFormat="MM/d/yyyy"     
            placeholderText="End Date Date"
            className={`datepicker-mode-${mode}`}
          />
          </div>
          
          <div className="flex w-[300px]">
          <MultiInput
            id="time_interval"
            name="time_interval"
            label="Time Interval"
            value={formData.time_interval}
            list={["year", "month", "week", "day"]}
            onChange={(e)=>handleInputChange(e)}
          />
          </div>
      </div>


      <div className="flex flex-col w-full p-2 border-b-gray-300 border-b-[1px] mb-3" >
      <MultiInput
        id="asset_type"
        name="asset_type"
        label="Asset Type"
        value={filterData.asset_type}
        list={["All", "Hardware", "Software", "Network Devices", "Cloud Computing", "Peripherals", "Other"]}
        onChange = {(e)=>handleFilterInputChange(e)}
      />

      <MultiInput
        id="product_category"
        name="product_category"
        label="Product Category"
        value={filterData.product_category}
        list={["All", "Laptops", "Servers", "Mobile Phones", "ERP", "CRM", "Other"]}
        onChange = {(e)=>handleFilterInputChange(e)}
      />

      <MultiInput
        id="business_unit"
        name="business_unit"
        label="Business Unit"
        value={filterData.business_unit}
        list={["All", "Sales", "Marketing", "Engineering", "Corporate", "Finance", "Customer Service"]}
        onChange = {(e)=>handleFilterInputChange(e)}
      />

      <MultiInput
        id="geography"
        name="geography"
        label="Geography"
        value={filterData.geography}
        list={["All", "North America", "EMEA", "APJ", "LATAM", "Other"]}
        onChange = {(e)=>handleFilterInputChange(e)}
      />

      </div>



  </div> */}

    </div>

    </div>
  )
}

export default Summary
