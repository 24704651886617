import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PowerManagement from './components/PowerManagement.js'

import Header from "./components/Header.js";
import Details from "./components/Details.js";
import Insights from './components/Insights.js';
import MapView from './components/MapView.js';
import Navbar from './components/Navbar.js';

// Set application details:
export const appName = "Data Center Manager"
export const logoFile = "Oomnitza_logo.png"
export const theme = "oomnie labs"
 
function App({contextApi}) {

  const dispatch = useDispatch();
  const mode = useSelector(state => state.environment.mode);
  const currentPage = useSelector(state => state.datacenter.currentPage);

  return (
    <div className={`flex flex-col w-full h-[100vh] overflow-hidden transition duration-500 bg-mode-${mode} `}>
        
        <Header contextApi ={contextApi} appName={appName} logo={logoFile}/>
        <Navbar contextApi={contextApi}/>
        {currentPage ==="details" && <Details contextApi={contextApi}/>}
        {currentPage ==="mapview" && <MapView contextApi={contextApi}/>}
        {currentPage ==="insights" && <Insights contextApi={contextApi}/>}

    </div>
  );
}

export default App;

