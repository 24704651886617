import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import NetworkGraph2d from './NetworkGraph2d';
import NetworkGraph3D from './NetworkGraph3d';
import Tree from './Tree'

import importedAssets from './sample_assets.json';


const Relationships = () => {

  const mode = useSelector(state => state.environment.mode);
  const [view, setView] = useState("3d")

  const [assets, setAssets] = useState([])
  
  useEffect(()=>{
    setAssets(importedAssets)
  },[])


  const dispatch = useDispatch()
  
  return (
    <div className="flex flex-col h-[100%] fade-in w-full overflow-y-scroll">
      <div>
        <div className="flex relative right-0 justify-between items-center me-3">
          <select className={`ms-3 button-mode-${mode}`} value={view} onChange={(e)=>setView(e.target.value)}>
            <option key={1} value="2d">2D</option>
            <option key={2} value="3d">3D</option>
            <option key={3} value="Tree">Tree</option>
          </select> 
      </div>

    </div>
    {/* {assets.length>0 &&
      <div className="flex w-full">
        <Tree mode={mode} assets = {assets}/>
        <NetworkGraph2d mode={mode} assets={assets}/>
        <NetworkGraph3D mode={mode} assets={assets}/>
      </div>
    } */}
      {
        view==="2d" ? <NetworkGraph2d mode={mode} assets={assets}/>
        : 
        view==="3d" ? <NetworkGraph3D mode={mode} assets={assets}/>
        :
        <Tree mode={mode} assets = {assets}/>
      }
    
    </div>
  )
}

export default Relationships
