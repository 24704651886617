export const documentationHtml =`

<div style="display: flex; flex-direction: column; width: 100%; padding: 20px; font-size: 12px; font-family: Arial; line-height: 1.5;">
  <h2 style="font-size: 24px; color: black; font-weight: bold; margin-bottom: 10px;">Oomnitza Workflow Configuration Change Documentation</h2>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Summary</h3>
  <p style="font-size: 12px; color: black;">
    The "Asset Update on Manager Change" workflow is designed to automatically update asset records in the Oomnitza system whenever there is a change in the Level 1 (L1) manager of a user. This workflow ensures that the asset records reflect the most current managerial assignments, thereby maintaining data accuracy and consistency across the system. The workflow is triggered by any change in the L1 manager field of a user record. Upon detection of such a change, the workflow initiates an API call to update the assigned user's L1 manager field in the asset records associated with the user.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Change Summary</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr style="background-color: white; color: black; font-weight: bold; font-size: 10px;">
        <th style="border: 1px solid lightgray; padding: 5px;">Change Date</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Changed By</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">September 14, 2023 15:47:54</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Chris Presnall</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Updated the workflow to include an API block for asset updates.</td>
      </tr>
    </tbody>
  </table>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Workflow Steps</h3>
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 1: Entry Point - If L1 Manager Changed</h4>
  <p style="font-size: 12px; color: black;">
    This step monitors the USERS object for any changes in the L1 manager field. It serves as the trigger for the workflow, initiating the subsequent steps when a change is detected.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 2: API Block</h4>
  <p style="font-size: 12px; color: black;">
    The API block performs a PATCH request to the Oomnitza API to update the asset records. It uses the user's username to filter the assets and updates the assigned user's L1 manager field with the new manager information.
  </p>
  
  <h4 style="font-size: 16px; color: gray; margin-top: 5px;">Step 3: End Point</h4>
  <p style="font-size: 12px; color: black;">
    This step marks the completion of the workflow process. Once the API call is successfully executed, the workflow concludes.
  </p>
  
  <h3 style="font-size: 20px; color: black; font-weight: bold; margin-top: 10px;">Technical Details</h3>
  <table style="width: 100%; border-collapse: collapse;">
    <thead>
      <tr style="background-color: white; color: black; font-weight: bold; font-size: 10px;">
        <th style="border: 1px solid lightgray; padding: 5px;">Parameter</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Value</th>
        <th style="border: 1px solid lightgray; padding: 5px;">Description</th>
      </tr>
    </thead>
    <tbody>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">HTTP Method</td>
        <td style="border: 1px solid lightgray; padding: 5px;">PATCH</td>
        <td style="border: 1px solid lightgray; padding: 5px;">The HTTP method used for the API request.</td>
      </tr>
      <tr style="background-color: white; color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">URL</td>
        <td style="border: 1px solid lightgray; padding: 5px;">https://{{GlobalSetting['Oomnitza.subdomain']}}.oomnitza.com/api/v3/assets/bulk_edit</td>
        <td style="border: 1px solid lightgray; padding: 5px;">The endpoint for the asset bulk edit API.</td>
      </tr>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">Headers</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Oomnitza-Ignore-Meta-Restriction: 1</td>
        <td style="border: 1px solid lightgray; padding: 5px;">Custom header to bypass meta restrictions.</td>
      </tr>
      <tr style="background-color: white; color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">Body</td>
        <td style="border: 1px solid lightgray; padding: 5px;">{ "filter": "(assigned_to eq '{{username}}')", "edits": { "assigned_user_s_l1_manager":"{{l1_manager}}" } }</td>
        <td style="border: 1px solid lightgray; padding: 5px;">The JSON payload for the API request, specifying the filter and edits to be made.</td>
      </tr>
      <tr style="background-color: rgb(245,245,245); color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">Timeout</td>
        <td style="border: 1px solid lightgray; padding: 5px;">30 seconds</td>
        <td style="border: 1px solid lightgray; padding: 5px;">The maximum time to wait for a response from the API.</td>
      </tr>
      <tr style="background-color: white; color: black; font-size: 10px;">
        <td style="border: 1px solid lightgray; padding: 5px;">Retries</td>
        <td style="border: 1px solid lightgray; padding: 5px;">3</td>
        <td style="border: 1px solid lightgray; padding: 5px;">The number of retry attempts in case of a failure.</td>
      </tr>
    </tbody>
  </table>
</div>

`

export default documentationHtml