import React, { useState, useEffect } from 'react';
import ForceGraph3D from 'react-force-graph-3d';
import * as THREE from 'three';
import Modal from 'react-modal'; // Ensure you have react-modal installed

// Sample data
const data = {
  nodes: [
    { id: 'Avik Ghosh', group: 'Employee'},
    { id: 'Donna Wilczek', group: 'Employee'},
    { id: 'Ramin Ettehad', group: 'Employee'},
    { id: 'Laptop1', group: 'Hardware'},
    { id: 'Laptop2', group: 'Hardware' },
    { id: 'Laptop3', group: 'Hardware'},
    { id: 'Software1', group: 'Software' },
    { id: 'Software2', group: 'Software' },
    { id: 'Software3', group: 'Software' },
    { id: 'Phone1', group: 'Hardware' },
    { id: 'Phone2', group: 'Hardware' },
    { id: 'App1', group: 'App' },
    { id: 'App2', group: 'App'}
  ],
  links: [
    { source: 'Avik Ghosh', target: 'Laptop1' },
    { source: 'Donna Wilczek', target: 'Laptop2' },
    { source: 'Ramin Ettehad', target: 'Phone1' },
    { source: 'Ramin Ettehad', target: 'Laptop3' },
    { source: 'Ramin Ettehad', target: 'Phone2' },
    { source: 'Laptop1', target: 'Software1' },
    { source: 'Laptop2', target: 'Software1' },
    { source: 'Laptop2', target: 'Software2' },
    { source: 'Laptop3', target: 'Software1' },
    { source: 'Laptop3', target: 'Software2' },
    { source: 'Laptop3', target: 'Software3' },
    { source: 'Phone1', target: 'App1' },
    { source: 'Phone1', target: 'App2' }
  ]
};

const NetworkGraph3D = (props) => {

  const mode = props.mode


  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  
  const [selectedNode, setSelectedNode] = useState(null); // State for the selected node
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Handle node click
  const handleNodeClick = (node) => {
    setSelectedNode(node); // Set the selected node
    // setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNode(null); // Clear the selected node
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <ForceGraph3D
        graphData={data}
        width={dimensions.width}
        height={dimensions.height}
        nodeAutoColorBy="group"
        onNodeClick={(e)=>handleNodeClick(e)} // Attach the click handler
        
        nodeThreeObject={node => {
          const colors = {
            'Employee': 'rgb(0,180,225)', 
            'Hardware': 'rgb(0,225,180)', 
            'Software': 'rgb(180,100,225)', 
            'App': 'rgb(225,100,100)'      
          };

          const sphere = new THREE.Mesh(
            new THREE.SphereGeometry(7),
            new THREE.MeshBasicMaterial({ color: colors[node.group] || 'gray' })
          );

          const sprite = new THREE.Sprite(
            new THREE.SpriteMaterial({
              map: new THREE.CanvasTexture(createTextSprite(node.id)),  
              depthWrite: false
            })
          );
          sprite.scale.set(30, 30, 30);

          const group = new THREE.Group();
          group.add(sphere);
          group.add(sprite);
          sprite.position.set(15, 15, 15);

          return group;
        }}

        linkColor={() => 'rgb(100,100,100)'}
        linkWidth={2}
        linkDirectionalParticles={2}
        linkDirectionalParticleSpeed={d => 0.004}
        backgroundColor="rgba(0,0,0,0)"  // Set background to transparent
        enableNodeDrag={true}
      />
      <div className={"text-white"}>{isModalOpen}</div>
        {selectedNode &&
          <div 
            className={`absolute z-900 panel-mode-${mode} w-[300px] h-[200px]`}>
            <h3>{selectedNode.id}</h3>
            <p>{selectedNode.group}</p>
            <div className={`button-mode-${mode}`} onClick={()=>closeModal}>Close</div>
          </div>
        }
      </div>
      
  );
};

// Helper function to create a text sprite for the node labels
function createTextSprite(text) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Set a larger canvas size to avoid text cutoff
  const canvasSize = 256;
  canvas.width = canvasSize;
  canvas.height = canvasSize;

  ctx.font = '40px Arial';  // Larger font size
  ctx.fillStyle = 'gray'; 
  ctx.textAlign = 'center';
  ctx.textBaseline = 'middle';
  ctx.fillText(text, canvasSize / 2, canvasSize / 2);  // Draw text in the center

  return canvas;
}

export default NetworkGraph3D;
