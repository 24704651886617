import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import '../kanban.css'; // Assume you have some CSS to style the Kanban board

// Define lifecycle stages
const stages = [
  'Planning',
  'Procurement',
  'Deployment',
  'In Operation',
  'Maintenance',
  'Disposal',
];

// Sample asset data
const initialAssets = [
  { id: '1', name: 'Asset 1', currentStage: 'Planning' },
  { id: '2', name: 'Asset 2', currentStage: 'Deployment' },
  { id: '3', name: 'Asset 3', currentStage: 'Maintenance' },
  { id: '4', name: 'Asset 4', currentStage: 'Procurement' },
  { id: '5', name: 'Asset 5', currentStage: 'In Operation' },
  { id: '6', name: 'Asset 6', currentStage: 'Disposal' },
  { id: '7', name: 'Asset 7', currentStage: 'Planning' },
  { id: '8', name: 'Asset 8', currentStage: 'Deployment' },
  { id: '9', name: 'Asset 9', currentStage: 'Maintenance' },
  { id: '10', name: 'Asset 10', currentStage: 'Procurement' },
  { id: '11', name: 'Asset 11', currentStage: 'In Operation' },
  { id: '12', name: 'Asset 12', currentStage: 'Disposal' },
  { id: '13', name: 'Asset 13', currentStage: 'Planning' },
  { id: '14', name: 'Asset 14', currentStage: 'Deployment' },
  { id: '15', name: 'Asset 15', currentStage: 'Maintenance' },
  { id: '16', name: 'Asset 16', currentStage: 'Procurement' },
  { id: '17', name: 'Asset 17', currentStage: 'In Operation' },
  { id: '18', name: 'Asset 18', currentStage: 'Disposal' },
];

const Kanban = (props) => {

  const mode = useSelector(state => state.environment.mode);


  // State to manage assets grouped by their lifecycle stage
  const [assets, setAssets] = useState(props.assets);
  const [stages, setStages] = useState(props.stages)

  // Function to reorder assets when dragged
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return; // Dropped outside the board

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return; // No movement
    }

    const updatedAssets = Array.from(assets);
    const [movedAsset] = updatedAssets.splice(source.index, 1);
    movedAsset.currentStage = destination.droppableId;

    updatedAssets.splice(destination.index, 0, movedAsset);
    setAssets(updatedAssets); // Update state
  };

  // Group assets by lifecycle stage
  const groupedAssets = stages.reduce((acc, stage) => {
    acc[stage] = assets.filter((asset) => asset.stage === stage);
    return acc;
  }, {});

  return (
    <div className={`kanban-board-mode-${mode}`}>
      {assets.length>0 &&
        <DragDropContext onDragEnd={handleOnDragEnd}>
          {stages.map((stage) => (
            <div key={stage} className={`kanban-column-mode-${mode}`}>
              <h3>{stage}</h3>
              <Droppable droppableId={stage}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className={`kanban-column-cards-mode-${mode}`}
                  >
                    {groupedAssets[stage].map((asset, index) => (
                      <Draggable key={asset.id} draggableId={asset.id} index={index}>
                        {(provided) => (
                          <div
                            className={`kanban-card-mode-${mode} flex flex-col`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="flex flex-col">
                              <div className="flex w-full font-bold text-[14px]"><span className={`text-[primary-color-mode-${mode}]`}>{asset.name}</span></div>
                              <div className={`flex w-full text-[11px] text-[tertiary-color-mode-${mode}]`}><span className={`text-[primary-color-mode-${mode}]`}>Commodity: {asset.commodity_type}</span></div>
                              <div className={`flex w-full text-[11px] text-[tertiary-color-mode-${mode}]`}><span className={`text-[primary-color-mode-${mode}]`}>Category: {asset.asset_category}</span></div>
                              
                              <div className={`flex w-full text-[11px]  text-[tertiary-color-mode-${mode}]`}>Assigned To: <span className={`text-[primary-color-mode-${mode}]`}>{asset.assigned_to_name}</span></div>
                              {asset.location ? <div className={`flex w-full text-[11px]  text-[tertiary-color-mode-${mode}]`}>Location: <span className={`text-[primary-color-mode-${mode}]`}>{asset.location}</span></div> : null}
                              {asset.asset_condition ?<div className={`flex w-full text-[11px]  text-[tertiary-color-mode-${mode}]`}>Condition: <span className={`text-[primary-color-mode-${mode}]`}>{asset.asset_condition}</span></div> : null}
                            </div>
                            <div>
                              <div className={`flex w-full text-[11px]   text-[tertiary-color-mode-${mode}]`}>Status: {asset.status}</div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      }
    </div>
  );
};

export default Kanban;
